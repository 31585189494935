<!--添加视频-->
<template>
  <div class="main">
    <div style="font-size: 26px; font-weight: bold; display: flex">
      <div style="color: #c8c8c8;cursor: pointer;" @click="goback">&lt;内容管理/</div>
      <div>添加内容</div>
    </div>
    <div class="title">
      <div class="titleleft">
        <div style="align-items: center">
          <div class="line"></div>
          <div>内容设置</div>
        </div>
        <el-form label-position="right" style="margin-top: 40px">
          <div style="display: flex">
            <!-- <el-form-item label="内容类型：">
              <el-select v-model="value" placeholder="请选择" disabled>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="等级：">
              <el-select v-model="levelId" placeholder="请选择">
                <el-option
                  v-for="item in levelList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="讲师：">
              <el-select v-model="teacherId" placeholder="请选择" @change="changeTeacher">
                <el-option
                  v-for="item in list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="品牌：" >
              <el-select v-model="brandId" placeholder="请选择"   >
                <el-option
                  v-for="item in brandList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                 
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="分类：">
              <el-select v-model="parentId" placeholder="请选择">
                <el-option
                  v-for="item in classList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> -->
            <el-form-item label="是否免费:" class="inp" style="display:flex;align-items: center;">
              <div style="display:flex;align-items: center;margin-left: 20px;">
                <el-radio v-model="freeFlag" label="0" style="display: flex;align-items: center;">不免费</el-radio>
                <el-radio v-model="freeFlag" label="1" style="display: flex;align-items: center;">免费</el-radio>
              </div>
            </el-form-item>
          </div>

          <el-form-item label="标题：" class="inp">
            <el-input v-model="name" placeholder="请输入标题"></el-input>
          </el-form-item>
          <el-form-item label="权重：" class="inp" v-if="!idd">
            <el-input v-model="weight" placeholder="权重数字越大，越靠前"></el-input>
          </el-form-item>
          <!-- <el-form-item label="课程包：" v-if="!idd">
              <el-select v-model="courseId" multiple  placeholder="请选择">
                <el-option
                  v-for="item in list2"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
          <el-form-item label="标题图片：" class="inp">
            <input type="file" @change="uploadimg" accept="image/*" ref="fileimg" id='fileimg' style="color: #fff" />
          </el-form-item>
          <el-form-item class="inp" v-if="img" label="图片：">
            <div style="position:relative;width:200px">
              <div style="width:100%;height:100%;position:absolute;background: rgba(0,0,0,0.1);"></div>
              <img :src="img" alt="" style="width: 200px" />
              <img @click="deleteImg" src="../../images/delete.png" alt=""
                style="z-index:999;position: absolute;width: 25px;height: 25px;left: 50%;top: 50%;transform: translate(-50%, -50%);width:25px;height:25px">
            </div>


            <!-- <video src=""></video> -->
          </el-form-item>
          <el-form-item label="标签：" class="inp">
            <el-input v-model="tag" placeholder="请输入标签"></el-input>
          </el-form-item>
          <!-- <el-form-item label="描述：">
            <el-input
              type="textarea"
              :rows="5"
              v-model="textarea"
              style="width: 480px"
            >
            </el-input>
             
          </el-form-item> -->
        </el-form>
      </div>
      <div style="flex-direction: column" v-if="value != 4 && value != 5">
        <div style="align-items: center">
          <div style="display: flex; flex-direction: column">
            <div style="align-items: center">
              <div class="line"></div>
              <div>视频上传</div>
            </div>
            <el-form label-position="left" style="margin-top: 40px">
              <el-form-item label="视频：" class="inp">
                <div style="display: flex; align-items: center">
                  <!-- <el-button
                    type="primary"
                    style="height: 34px; line-height: 10px"
                    >+添加文件</el-button
                  > -->
                  <!-- <el-upload
                  class="upload-demo"
                  action="#"
                  :on-change="uploadchange"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :file-list="fileList"
                > -->
                  <input type="file" accept='video/mp4' ref='file' id='file'
                    style="height: 34px; line-height: 10px;color:#fff" @change="uploadvideo" />
                  <!-- <input
                    type="file"
                    accept="video/mp4"
                    ref="file"
                    id="file"
                    style="height: 34px; line-height: 10px; color: #fff"
                  /> -->
                  <!-- </el-upload> -->
                  <!-- <div
                    style="color: #a9a9a9; font-size: 16px"
                   
                  >
                    *未选择任何文件
                  </div> -->
                </div>
              </el-form-item>
              <el-form-item>
                <!-- <el-upload
                  class="upload-demo"
                  action="#"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :file-list="fileList"
                >
                  <el-button size="small" type="primary">点击上传</el-button> -->
                <!-- <div slot="tip" class="el-upload__tip">
                    只能上传jpg/png文件，且不超过500kb
                  </div> -->
                <span slot="label"><span style="color: #f56c6c; font-size: 14px"> </span></span>

                <video :src="vidersrc" v-if="vidersrc" class="avatar" controls="controls"
                  style="width: 351px; height: 198px" id="videosrc">
                  您的浏览器不支持视频播放
                </video>
                <el-progress type="circle" :percentage="percentage" style="
                    width: 351px;
                    height: 198px;
                    justify-content: center;
                    align-items: center;
                  " v-if="!vidersrc"></el-progress>
                <!-- </el-upload> -->
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <quill-editor style="height:390px;width:100%;" v-model="msg" ref="richAnalysis" :options="options2"
      @change="quillchange"></quill-editor>

    <div style="display: flex;margin-top: 40px;">
      <el-button type="primary" style="margin: 20px auto; width: 200px" @click="add">添加</el-button>
      <input type="file" @change="uploadimg3" accept='image/*' ref='file3' id="file3" style="display:none" />
      <!-- <el-button
        type="primary"
        style="margin: 20px auto; width: 200px"
        @click="base_videoInfo"
        >添加2</el-button>
    </div> -->
    </div>
  </div>
</template>
// <script type="text/javascript" src="https://unpkg.com/mediainfo.js/dist/mediainfo.min.js"></script>
<script>
// 工具栏配置
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
    
      [{'header': 1}, {'header': 2}],               // custom button values
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
      [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
      [{'direction': 'rtl'}],                         // text direction
    
      [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
    
      [{'color': []}, {'background': []}],          // dropdown with defaults from theme
      [{'font': []}],
      [{'align': []}],
      ['link', 'image', 'video'],
      ['clean']                                         // remove formatting button
    ]
// const MediaInfo = require('mediainfo.js')
import MediaInfo from "mediainfo.js";
import { levelList, contentsaveOrUpdate, getCosCredit ,getRichText,teacherList,contentsaveOrAdd,courseInfoList,saveAndToCourse,addcontent} from "../../apis/index";

var COS = require("cos-js-sdk-v5");
var cos = new COS({
  getAuthorization: function (options, callback) {
    getCosCredit().then((result) => {
      var data = result.data.data;
      var credentials = data && data.credentials;
      if (!data || !credentials) return console.error("credentials invalid");
      sessionStorage.setItem("bucket", data.bucket);
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
      });
    });
  },
});
export default {
  data() {
    return {
      freeFlag:'0',
        brandList:[],
        brandId:'',
        
      contentId:'',
      weight:'',
      levelIds:'',
      idd:'',
      courseId:'',
      name:'',
      teacherId:'',
      teacherName:'',
      list:[],
      list2:[],
      tag:'',
      taskId:'',
      msg:'',
      options2: {
        theme: "snow",
        placeholder: "请输入内容",
        modules:{
          toolbar:{
             container: toolbarOptions,  // 工具栏
              handlers: {
                                'image': function (value) {
                                    if (value) {
                                      document.getElementById('file3').click()
                                        // alert('自定义图片')
                                    } else {
                                        this.quill.format('image', false);
                                    }
                                }
                            }
          }
        }
        // modules: {
        //   toolbar: [
        //     ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
        //     //['blockquote', 'code-block'],                      //引用，代码块
        //     [{ header: 1 }, { header: 2 }], // 几级标题
        //     [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
        //     [{ script: "sub" }, { script: "super" }], // 下角标，上角标
        //     [{ indent: "-1" }, { indent: "+1" }], // 缩进
        //     //[{ direction: "rtl" }], // 文字输入方向
        //     [{ size: ["small", false, "large", "huge"] }], // 字体大小
        //     [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
        //     [{ color: [] }, { background: [] }], // 颜色选择
        //     [{ font: [] }], // 字体
        //     [{ align: [] }], // 居中
        //     ["clean"],　　　　//清除样式
        //     [("link", "image","video")],//控制能上传的内容
        //   ],
        // },
      },
      enter:false,
      img: "",
      percentage: 0,
      selectFileobj: {},
      id: "",
      name: "",
      levelList: [],
      levelId: "0",
      input: "",
      vidersrc: "",
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      textarea: "",
      value: "2",
      checkList: [],
      radio: "1",
      options: [
        {
          value: "3",
          label: "富文本",
        },
        {
          value: "2",
          label: "视频",
        },
      ],
    };
  },
  watch: {},

  created() {
     this.brandList = this.$store.state.brandList
    let courseId=this.$route.query.id
    this.brandId=this.$route.query.brandId
    this.courseId=courseId
    return
    if (this.$route.query.id) {
     Promise.all([this.teacherlistg(),this.courseInfoListg(),this.leave()]).then((res) => {
        let row = this.$route.query;
        // console.log(row)
        (this.levelIds = Number(row.levelId)),
          (this.name = row.name),
          (this.textarea = row.des),
          (this.vidersrc = row.videoUri),
          (this.id = row.id);
          this.teacherId=Number(row.teacherUserId)
          this.teacherName=row.teacherName
        this.img = row.logoUri;
        // this.courseId=Number(row.courseId)
        this.tag=row.tag
       
      });
      getRichText(this.$route.query.resourceUri).then(res=>{
       this.msg=res.data
    })
    } else {
      if(sessionStorage.getItem('vidersrc')){
        this.vidersrc=sessionStorage.getItem('vidersrc')
      }
      this.leave()
      this.teacherlistg()
      this.courseInfoListg()
      // console.log(this.levelId)
    }
  },
  beforeDestroy(){
      cos.cancelTask(this.taskId)//离开页面取消上传视频任务 
  },
  methods: {

    changeTeacher(e){
     let obj=this.list.find(item=>{
        return e==item.id
      })
      this.teacherId=obj.id
      this.teacherName=obj.name
    },
     async teacherlistg(){
     const {data}=await teacherList()
     if(data.code==200){
        this.list=data.data
        console.log(data.data)
     }else{
       this.$message.error(data.msg)
     }
     
    },
    async courseInfoListg(){
      const params={
        levelId:this.levelIds,
        pageIndex:1,
        pageSize:999
      }
     const {data}=await courseInfoList(params)
     if(data.code==200){
        this.list2=data.data.records
        console.log(data.data)
     }else{
       this.$message.error(data.msg)
     }
     
    },
    quillchange(delta, oldDelta, source){
      console.log(delta, oldDelta, source)
    },
    deleteImg(){//删除图片
    console.log('删除图片')
    this.img=''
    },
    base_videoInfo() {
      const fileinput = document.getElementById("file");
      console.log(fileinput)
      const onChangeFile = (mediainfo) => {
        console.log("监听上了");
        const file = fileinput.files[0];
        if (file) {
          const getSize = () => file.size;
          const readChunk = (chunkSize, offset) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (event) => {
                if (event.target.error) {
                  reject(event.target.error);
                }
                resolve(new Uint8Array(event.target.result));
              };
              reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
            });
          mediainfo
            .analyzeData(getSize, readChunk)
            .then((result) => {
              console.log(result);
              console.log(result.media.track[1].Format);
              // console.log(result.media.track[1].Format)
              //result.media.track[1].Format == "AVC"说明是h264
              if (result.media.track[1].Format != "AVC") {
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      };
      MediaInfo({ format: "object" }, (mediainfo) => {
        //format结果值的格式（选择：object，JSON，XML，HTML或text）
        fileinput.addEventListener("change", () => onChangeFile(mediainfo));
      },(err)=>{
        console.log(err)
      });
    },
    uploadvideo(e) {
      //上传视频
      if (this.$refs["file"].files[0]) {
        let filename = this.$refs["file"].files[0].name;
        let selectFileobj = this.$refs["file"].files[0];
        console.log(selectFileobj);
        this.vidersrc=''
        this.upload(selectFileobj)
        // this.base_videoInfo()
      }
    },

    upload(selectFileobj) {
      let that = this;
      cos.options.getAuthorization("", function (obj) {
        let name = selectFileobj.name.replace(/MP4/g, "mp4");
        if(that.levelId==''){
          that.$message.error('请先选择等级')
          document.getElementById('file').value=''
          return 
        }
        let filename ='content/resource/LV'+that.levelId+'/'+'video/'+
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
          console.log(filename)
        cos.sliceUploadFile(
          {
            Bucket: sessionStorage.getItem("bucket") /* 必须 */,
            Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
            Key: filename /* 必须 */,
            Body: selectFileobj /* 必须 */,
            onTaskReady: function (taskId) {
              /* 非必须 */
              console.log(taskId);
              that.taskId=taskId
            },
            onHashProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
            },
            onProgress: function (progressData) {
              /* 非必须 */
              console.log(JSON.stringify(progressData));
              that.percentage = parseInt(progressData.percent * 100);
            },
          },
          function (err, data) {
            console.log(err || data);
            if (data) {
               let Location=data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g,'https://sihuankangyuan.cn')
              // that.vidersrc = "https://" + data.Location;
              that.vidersrc=Location

              sessionStorage.setItem('vidersrc',Location)

               setTimeout(()=>{
                //   var videosrc = document.getElementById('videosrc');
                // that.duration=parseInt(videosrc.duration)
                // console.log(parseInt(videosrc.duration))
                 that.$message({
                  message: `上传成功，视频正在转码请稍后再播放`,
                  type: 'warning',
                });
               },1000)
            }
          }
        );
      });
    },
     richText(selectFileobj){
      let that=this
    //    if(that.levelId==''){
    //       that.$message.error('请先选择等级')
    //       return 
    //     }
        let randomNum=Math.random().toString().slice(2)
        let filename ='content/resource/LV'+that.levelId+'/'+'richText/'+randomNum+new Date().getTime()
        this.filename=filename
          console.log(filename)
     var cos = new COS({
            getAuthorization: function (options, callback) {
              getCosCredit().then(result=>{
                 var data = result.data.data;
                        var credentials = data && data.credentials;
                        if (!data || !credentials) return console.error('credentials invalid');
                       sessionStorage.setItem('bucket', data.bucket)
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            XCosSecurityToken: credentials.sessionToken,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                        });
              })
            }
        });
        cos.options.getAuthorization ('',function(obj){
          cos.putObject({
          Bucket: sessionStorage.getItem('bucket'), /* 必须 */
          Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
          Key: filename,              /* 必须 */
          StorageClass: 'STANDARD',
          Body: selectFileobj, // 上传文件对象
          onProgress: function(progressData) {
              // console.log(JSON.stringify(progressData));
              console.log(progressData)
          }
        }, function(err, data) {
          console.log(err || data);
          if(data){
        //     let courseId=that.courseId
        //    let courseContentSaveList= courseId.map(item=>{
        //      return {
        //        contentId:that.contentId,
        //        courseId:item,
        //        weight:that.weight
        //      }
        //     })
           const params = {
        // type: this.value,
            brandId: that.brandId,
            courseId: Number(that.courseId),
            name: that.name,
            // des: that.textarea,
            videoUri: that.vidersrc,
            resourceUri:that.filename,
            logoUri: that.img,
            freeFlag:that.freeFlag
            // id: that.id,
            // courseIdList:that.courseId,
            // courseContentSaveList,
            // teacherName:that.teacherName,
            // teacherUserId:that.teacherId,
            // tag:that.tag
          };
          console.log(params)
           const params2 = {
        // type: this.value,
            type: 4,//富文本和视频合并
            levelId: that.levelId,
            name: that.name,
            des: that.textarea,
            videoUri: that.vidersrc,
            resourceUri:that.filename,
            logoUri: that.img,
            id: that.id,
            courseIdList:that.courseId,
            // courseContentSaveList,
            teacherName:that.teacherName,
            teacherUserId:that.teacherId,
            tag:that.tag
          };
          if(that.$route.query.id){//修改
            // contentsaveOrUpdate(params2).then(res=>{
            //       if(res.data.code==200){
            //     that.$message.success('操作成功')
            //     that.$router.go(-1)
            //   }else{
            //     that.$message.error(res.data.msg)
            //   }
            //     })
            addcontent(params).then(res=>{
                  if(res.data.code==200){
                that.$message.success('操作成功')
                that.$router.go(-1)
              }else{
                that.$message.error(res.data.msg)
              }
                })
          }else{
            // contentsaveOrAdd(params).then(res=>{
            //       if(res.data.code==200){
            //     that.$message.success('操作成功')
            //     that.$router.go(-1)
            //   }else{
            //     that.$message.error(res.data.msg)
            //   }
            //     })


            addcontent(params).then(res=>{
                  if(res.data.code==200){
                that.$message.success('操作成功')
                that.$router.go(-1)
              }else{
                that.$message.error(res.data.msg)
              }
                })
          }
         
        console.log(params)
            //   let url='https://'+data.Location
            //  let quill = that.$refs.richAnalysis.quill
            //  let length = quill.getSelection().index;
            // // 插入图片，url为服务器返回的图片链接地址
            // quill.insertEmbed(length, 'image', url)
            // // 调整光标到最后
            // quill.setSelection(length + 1)
          }
        });
        })
         
    },
    uploadimg(e) {
      //上传图片
      if (this.$refs["fileimg"].files[0]) {
        let filename = this.$refs["fileimg"].files[0].name;
        let selectFileobj = this.$refs["fileimg"].files[0];
        console.log(this.$refs["fileimg"].files[0])
        // console.log(selectFileobj)
        this.upload2(selectFileobj);
      }
    },
    uploadimg3(e){//上传图片
    
      if(this.$refs['file3'].files[0]){
        let filename = this.$refs['file3'].files[0].name
        let selectFileobj = this.$refs['file3'].files[0]
        // console.log(selectFileobj)
        this.upload3(selectFileobj)

      }
    },
     upload3(selectFileobj){
      let that=this
      let name = selectFileobj.name
       if(that.levelId==''){
          that.$message.error('请先选择等级')
          document.getElementById('file3').value=''
          return 
        }
        let filename ='content/resource/LV'+that.levelId+'/'+'image/'+
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
          console.log(filename)
     var cos = new COS({
            getAuthorization: function (options, callback) {
              getCosCredit().then(result=>{
                 var data = result.data.data;
                        var credentials = data && data.credentials;
                        if (!data || !credentials) return console.error('credentials invalid');
                       sessionStorage.setItem('bucket', data.bucket)
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            XCosSecurityToken: credentials.sessionToken,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                        });
              })
            }
        });
        cos.options.getAuthorization ('',function(obj){
          cos.putObject({
          Bucket: sessionStorage.getItem('bucket'), /* 必须 */
          Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
          Key: filename,              /* 必须 */
          StorageClass: 'STANDARD',
          Body: selectFileobj, // 上传文件对象
          onProgress: function(progressData) {
              // console.log(JSON.stringify(progressData));
              console.log(progressData)
          }
        }, function(err, data) {
          console.log(err || data);
          if(data){
           
              // let url='https://'+data.Location
               let url=data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g,'https://sihuankangyuan.cn')
             let quill = that.$refs.richAnalysis.quill
             let length = quill.getSelection().index;
            // 插入图片，url为服务器返回的图片链接地址
            quill.insertEmbed(length, 'image', url)
            // 调整光标到最后
            quill.setSelection(length + 1)
          }
        });
        })
         
    },
    upload2(selectFileobj) {
      let that = this;
      let name = selectFileobj.name
       if(that.levelId==''){
          that.$message.error('请先选择等级')
          document.getElementById('fileimg').value=''
          return 
        }
        let filename ='content/resource/LV'+that.levelId+'/'+'image/'+
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
          console.log(filename)
      cos.options.getAuthorization("", function (obj) {
        cos.putObject(
          {
            Bucket: sessionStorage.getItem("bucket") /* 必须 */,
            Region: "ap-nanjing" /* 存储桶所在地域，必须字段 */,
            Key: filename /* 必须 */,
            StorageClass: "STANDARD",
            Body: selectFileobj, // 上传文件对象
            onProgress: function (progressData) {
              // console.log(JSON.stringify(progressData));
              console.log(progressData);
            },
          },
          function (err, data) {
            console.log(err || data);
            if (data) {
              let Location=data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g,'https://sihuankangyuan.cn')
              // let url = "https://" + data.Location;
              
              //  console.log(Location)
              that.img = Location;
            }
          }
        );
      });
    },
    async leave() {
      //获取等级列表
      const params = {
        roleId: 3,
      };
      const { data } = await levelList(params);
      if (data.code == 200) {
        // const obj={
        //    id:'',
        //    name:'全部'
        //  }
        //  this.levelList=[obj,...data.data]
        this.levelList = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
    async add() {
      sessionStorage.setItem('vidersrc','')
      this.richText(this.msg)
      // const params = {
      //   // type: this.value,
      //   type: 4,//富文本和视频合并
      //   levelId: this.levelId,
      //   name: this.name,
      //   des: this.textarea,
      //   videoUri: this.vidersrc,
      //   resourceUri:this.msg,
      //   logoUri: this.img,
      //   id: this.id,
      // };
      // const { data } = await contentsaveOrUpdate(params);
      // if (data.code == 200) {
      //   this.$message.success("操作成功");
      //   this.$router.go(-1);
      // } else {
      //   this.$message.error(data.msg);
      // }
    },
    goback() {
      if(this.percentage==100){//视频已上传完退出提示保存
        this.$confirm('请确认是否已保存内容?', '提示', {
          confirmButtonText: '已保存',
          cancelButtonText: '忘了保存',
          type: 'warning'
        }).then(() => {
                this.$router.go(-1);
        }).catch(() => {

        })
      }else if(this.percentage!=100 && this.percentage!=0){
        this.$confirm('视频正在上传，退出将取消上传任务?', '提示', {
          confirmButtonText: '确认退出',
          cancelButtonText: '取消退出',
          type: 'warning'
        }).then(() => {
                this.$router.go(-1);
        }).catch(() => {

        })
      }else{
        this.$router.go(-1);
      }
       
               
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>

<style lang='less' scoped>
.main {
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  div {
    display: flex;
  }

  .line {
    width: 9px;
    height: 22px;
    border-radius: 6px;
    background: #409eff;
    margin-right: 11px;
  }
}

.title>div {
  width: 48%;
  margin: 29px 0;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  //   align-items: center;

}

.titleleft {
  display: flex;
  flex-direction: column;
}

/deep/.el-form-item__label {
  width: 100px;
}

/deep/.el-radio__label,
/deep/.el-checkbox__label {
  display: flex;
  align-items: center;
}

.inp /deep/.el-form-item__content {
  width: 88%;
}
</style>
